@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$blue: #339BEB;

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

#dashboard-display
{
  .dashboard-display-item{
    min-width: 140px;

    a{
      :hover{
        text-decoration: none;
      }
      .dashboard-item{
        border:1px solid #e8e8e8;
        padding:12px;
        @include rounded(12px);
      }
    }
  }

}

#roles_list{
  .role_disabled{
    color: #ccc;
  }
}

.role_details_pull_left{
  padding: 0 24px 24px 0;
}

#footer_options{
  font-size: 10px;
}

.header{
  .site-name{
    font-size:30px;
  }
}

#about_lhs{
  text-align: center;

  img{
    height:320px;
    margin:auto;
  }
}

.charities-list{
  img{
    margin:auto;
  }
}
.charities-blurb{
  span{
    color: $blue;
  }
}



.landing-page{

  .container{
    h1 {
      font-size:3rem;
    }
    .container{
      top:-30%;
    }
    .col-xs-4{
      font-size:1rem;
    }

    /*
  ####################################################
  M E D I A  Q U E R I E S
  ####################################################
  */

    /*
    ::::::::::::::::::::::::::::::::::::::::::::::::::::
    Bootstrap 4 breakpoints
    */
    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
      .col-xs-4{
        font-size:1.1rem;
      }
      .site-name{
        font-size:9px
      }
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
      .col-xs-4{
        font-size:1.25rem;
      }
      .site-name{
        font-size:9px
      }
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      .col-xs-4 > a{
        font-size:1.5rem;
      }
      .site-name{
        font-size:9px
      }
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
      .col-xs-4 > a{
        font-size:2rem;
      }
      .site-name{
        font-size:9px
      }
    }

  }

  /*
Extra small devices (portrait phones, less than 544px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/


}



